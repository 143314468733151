import { useEffect, useState } from "react";
import { SelectForMember as SELECT } from "src/component/api/Dashboard/index";
import { LOGOUT } from "src/component/helper/constants";
export type ImageProps = {
  list: any;
  ProductMasterID: number;
  ProductType: string;
  FileList: string;
  ProductName: string;
};
const UseMemberDashboard = () => {
  const [groupAdmin, setGroupAdmin] = useState(0);
  const [user, setUser] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [totalSubscribers, setSubscribers] = useState(0);
  const [totalInvestBank, setTotalInvestBank] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [totalExpence, setTotalExpence] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [memberNews, setDashboardNews] = useState("");
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [loanAmount, setLoanAmount] = useState(0);
  const [groupIncome, setGroupIncome] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [villageName, setVillageName] = useState("");
  const [productPath, setProductPath] = useState("");
  const [list, setList] = useState<ImageProps[]>();

  useEffect(() => {
    select();
  }, []);

  const select = async () => {
    const response = await SELECT();
    if (!response?.isAuthorized) LOGOUT();
    if (!response?.status) {
      return;
    }
    var data = JSON.parse(response?.data!);
    const productPath = data.ProductPath || [];

    const fileList = productPath.map(
      (item: { FileList: any }) => item.FileList
    );
    setList(fileList);
    setProductPath(fileList);
    setGroupAdmin(data.TotalGroupAdmin);
    setTotalUser(data.TotalUser);
    setGroupName(data.GroupName);
    setStartDate(data.StartDate);
    setDashboardNews(data.News);
    setUser(data.TotalUser);
    setTotalExpence(data.TotalExpence);
    setTotalInvestment(data.TotalInvestment);
    setTotalInvestBank(data.TotalInvestmentWithBank);
    setLoanAmount(data.TotalLoan);
    setGroupName(data.GroupName);
    setVillageName(data.VillageName);
    setStartDate(data.StartDate);
    setGroupIncome(data.GroupIncome);
    setRemainingAmount(data.RemainingAmount);
    setDashboardNews(data.News);
  };

  return {
    memberNews,
    groupAdmin,
    totalUser,
    groupName,
    startDate,
    totalSubscribers,
    totalInvestBank,
    user,
    totalExpence,
    totalInvestment,
    loanAmount,
    productPath,
    villageName,
    list,
    groupIncome,
    remainingAmount,
  };
};
export default UseMemberDashboard;
