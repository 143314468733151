import { useTranslation } from "react-i18next";

export const GetMenuItemTranslation = (itemName: string) => {
  const { t } = useTranslation("mainMenuList");
  const translations: Record<string, { text: string; icon: string }> = {
    "Details Register": {
      text: t("Details Register"),
      icon: "MainMenu/details-register.svg",
    },
    "Group Register": {
      text: t("Group Register"),
      icon: "MainMenu/group-register.svg",
    },
    Home: { text: t("Home"), icon: "MainMenu/home.svg" },
    Register: { text: t("Register"), icon: "MainMenu/icon2.jpg" },
    Loan: { text: t("Loan"), icon: "MainMenu/loan.svg" },
    User: { text: t("User"), icon: "MainMenu/user.svg" },
    Investment: { text: t("Investment"), icon: "MainMenu/investment.svg" },
    Installment: { text: t("Installment"), icon: "MainMenu/installment.svg" },
    Master: { text: t("Master"), icon: "MainMenu/master.svg" },
    Expense: { text: t("Expense"), icon: "MainMenu/expense.svg" },
    Report: { text: t("Report"), icon: "MainMenu/report.svg" },
    Setting: { text: t("Setting"), icon: "MainMenu/setting.svg" },
    Product: { text: t("Product"), icon: "MainMenu/product.svg" },
    News: { text: t("News"), icon: "MainMenu/product.svg" },
    Notice: { text: t("Notice"), icon: "MainMenu/product.svg" },
    "Balance Sheet": { text: t("Balance Sheet"), icon: "MainMenu/product.svg" },
    "All User Report": {
      text: t("All User Report"),
      icon: "MainMenu/all-user.svg",
    },
    "Subscription Charge": {
      text: t("Subscription Charge"),
      icon: "MainMenu/subscription-charge-icon.svg",
    },
    "Group Income": {
      text: t("Group Income"),
      icon: "MainMenu/group-income.svg",
    },
    Service: { text: t("Service"), icon: "MainMenu/payment-getway.svg" },
    "Company Subscription": {
      text: t("Company Subscription"),
      icon: "MainMenu/payment-getway.svg",
    },
  };
  const translation = translations[itemName] || { text: itemName, icon: "" };
  return translation;
};

export const GetSubMenuItemTranslation = (itemName: string) => {
  const { t } = useTranslation("subMenuList");
  const translations: Record<string, { text: string; icon: string }> = {
    Member: { text: t("Member"), icon: "MainMenu/member.svg" },
    Employee: { text: t("Employee"), icon: "MainMenu/employee.svg" },
    "Saving Groups": {
      text: t("Saving Groups"),
      icon: "MainMenu/saving-group.svg",
    },
    "Add Loan": { text: t("Add Loan"), icon: "MainMenu/add-loan.svg" },
    "Loan Report": { text: t("Loan Report"), icon: "MainMenu/loan-report.svg" },
    "All User": { text: t("All User"), icon: "MainMenu/all-user-submenu.svg" },
    "Other Investment": {
      text: t("Other Investment"),
      icon: "MainMenu/other-investment.svg",
    },
    "Investment Report": {
      text: t("Investment Report"),
      icon: "MainMenu/investment-report.svg",
    },
    "Pay Installment": {
      text: t("Pay Installment"),
      icon: "MainMenu/pay-installment.svg",
    },
    "Bachat Report": {
      text: t("Bachat Report"),
      icon: "MainMenu/bachat-report.svg",
    },
    "Loan Installment Report": {
      text: t("Loan Installment Report"),
      icon: "MainMenu/loan-installment-report.svg",
    },
    "Expense Type": {
      text: t("Expense Type"),
      icon: "MainMenu/expense-type.svg",
    },
    "Expense Sub Type": {
      text: t("Expense Sub Type"),
      icon: "MainMenu/expense-subtype.svg",
    },
    "Add Product": { text: t("Add Product"), icon: "MainMenu/add-product.svg" },
    "Add Expense": { text: t("Add Expense"), icon: "MainMenu/add-expense.svg" },
    "Expense Claim Report": {
      text: t("Expense Claim Report"),
      icon: "MainMenu/expense-claim-report.svg",
    },
    "Investment Type": {
      text: t("Investment Type"),
      icon: "MainMenu/investment-type.svg",
    },
    "User Report": { text: t("User Report"), icon: "MainMenu/all-user.svg" },
    "Sent SMS Report": {
      text: t("Sent SMS Report"),
      icon: "MainMenu/sent-sms-report.svg",
    },
    "Key Setting": { text: t("Key Setting"), icon: "MainMenu/key-setting.svg" },
    "Message Setting": {
      text: t("Message Setting"),
      icon: "MainMenu/message-setting.svg",
    },
    "SMS Setting": { text: t("SMS Setting"), icon: "MainMenu/sms-setting.svg" },
    "Product Type": {
      text: t("Product Type"),
      icon: "MainMenu/product-type.svg",
    },
    "Add Income": { text: t("Add Income"), icon: "MainMenu/add-income.svg" },

    "Income Report": {
      text: t("Income Report"),
      icon: "MainMenu/income-report.svg",
    },
    "Bachat Gat": { text: t("Bachat Gat"), icon: "MainMenu/bachat-report.svg" },
    "Add News": { text: t("Add News"), icon: "MainMenu/news-icon.svg" },
    Report: { text: t("Report"), icon: "MainMenu/news-report-icon.svg" },
    "Add Slider": { text: t("Add Slider"), icon: "MainMenu/gallary-icon.svg" },
    "Add Notice": { text: t("Add Notice"), icon: "MainMenu/notice-icon.svg" },
    "Monthly Balance Sheet": {
      text: t("Monthly Balance Sheet"),
      icon: "MainMenu/total_balance_sheet.svg",
    },
    "Total Balance Sheet": {
      text: t("Total Balance Sheet"),
      icon: "MainMenu/total_balance_sheet.svg",
    },
    "Notice Report": {
      text: t("Notice Report"),
      icon: "MainMenu/notice-report-icon.svg",
    },
    "Income Type": {
      text: t("Income Type"),
      icon: "MainMenu/notice-report-icon.svg",
    },
  };
  const translation = translations[itemName] || itemName;
  return translation;
};
