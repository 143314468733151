import { PostAPI, PostAPIWithoutRequest } from "src/component/api/helper/apiHelper";

export const Select = async(request: string) => {
    return PostAPI(request,"Dashboard/Select");
};
export const SelectForCompany = async () => {
  return PostAPIWithoutRequest("Dashboard/SelectForCompany");
};
export const SelectForMember = async () => {
  return PostAPIWithoutRequest("Dashboard/SelectForMember");
};
export const SelectForCompanySupport = async () => {
  return PostAPIWithoutRequest("Dashboard/SelectForCompanySupport");
};