import { useEffect, useState } from "react";
import { Select as SELECT } from "src/component/api/Dashboard/index";
import { LOGOUT } from "src/component/helper/constants";
import { ToastError } from "src/component/uiElements/toast";
import { SelectSavingsGroupListForGroupAdmin as SelectGroupList } from "src/component/api/savingsGroup/savingsGroup";
export type ImageProps = {
  list: any;
  ProductMasterID: number;
  ProductType: string;
  FileList: string;
  ProductName: string;
};
export type SavingGroup = {
  SavingGroupsID: number;
  GroupName: string;
};
const UseGroupAdminDashboard = () => {
  const [user, setUser] = useState(0);
  const [totalExpence, setTotalExpence] = useState(0);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [loanAmount, setLoanAmount] = useState(0);
  const [groupIncome, setGroupIncome] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [villageName, setVillageName] = useState("");
 
  const [list, setList] = useState<ImageProps[]>();
  const [startDate, setStartDate] = useState("");
  const [news, setDashboardNews] = useState("");

  const [totalInvestBank, setTotalInvestBank] = useState(0);
  const [savingGroupList, setSavingGroupList] = useState<SavingGroup[]>([]);
 
  const DefultGroupName = {
    SavingGroupsID: 0,
    GroupName: "Select group",
  };

  useEffect(() => {
    SelectGroup();
  }, []);
  const SelectGroup = async () => {
    setSavingGroupList([]);
    const response = await SelectGroupList();
    if (!response?.isAuthorized) LOGOUT();
    if (!response?.status) {
      let failResult = JSON.parse(response?.data!);
      ToastError(failResult.Message);
      return;
    }
    const data = JSON.parse(response?.data!);

    setSavingGroupList(data);
  };

  const select = async (SavingGroupsID: number) => {
    const inputObj = {
      SavingGroupsID: SavingGroupsID,
    };
    const request = JSON.stringify(inputObj);

    const response = await SELECT(request);
    if (!response?.isAuthorized) LOGOUT();
    if (!response?.status) {
      return;
    }
    var data = JSON.parse(response?.data!);
    const productPath = data.ProductPath;
    const fileList = productPath.map(
      (item: { FileList: any }) => item.FileList
    );

    setList(fileList);
    //setProductPath(fileList);
    setUser(data.TotalUser);
    setTotalExpence(data.TotalExpence);
    setTotalInvestment(data.TotalInvestment);
    setTotalInvestBank(data.TotalInvestmentWithBank);
    setLoanAmount(data.TotalLoan);
    setGroupName(data.GroupName);
    setVillageName(data.VillageName);
    setStartDate(data.StartDate);
    setGroupIncome(data.GroupIncome);
    setRemainingAmount(data.RemainingAmount);
    setDashboardNews(data.News);
  };

  return {
    totalInvestBank,
    news,
    user,
    totalExpence,
    savingGroupList,
    totalInvestment,
    loanAmount, 
    groupName,
    villageName,
    list,
    startDate,
    select,
    groupIncome,
    remainingAmount,
  };
};
export default UseGroupAdminDashboard;
