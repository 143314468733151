import { useEffect, useState } from "react";
import { SelectForCompany as SELECT } from "src/component/api/Dashboard/index";
import { LOGOUT } from "src/component/helper/constants";

export type NewsReport = {
  UserID: number;
  Message: string;
  HyperLink: string;
};

const UseCompanyDashboard = () => {
  const [groupAdmin, setGroupAdmin] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [totalSubscribers, setSubscribers] = useState(0);
  const [companyName, setCompanyName] = useState(""); 
  const [dashboardnews, setDashboardNews] = useState("");

  useEffect(() => {
    select();
  }, []);

  const select = async () => {
    const response = await SELECT();
    if (!response?.isAuthorized) LOGOUT();
    if (!response?.status) {
      return;
    }
    var data = JSON.parse(response?.data!); 
    setGroupAdmin(data.TotalGroupAdmin);
    setTotalUser(data.TotalUser);
    setSubscribers(data.TotalSubscribers);
    setCompanyName(data.CompanyName); 
    setDashboardNews(data.News); 
  };

  return { 
    dashboardnews,
    groupAdmin,
    totalUser,
    companyName,
    totalSubscribers,
  };
};
export default UseCompanyDashboard;
